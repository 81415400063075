<template>
  <v-data-table
      dense
      :headers="headers"
      :items="popupResponses"
      :options.sync="options"
      :server-items-length="totalPopupResponses"
      :footer-props="footerProps"
      :items-per-page="50"
      item-key="id"
      sort-desc
      class="elevation-1"
      :loading="loading"
      loading-text="Loading... Please wait"
  >
    <template v-slot:top>
      <v-toolbar
          flat
      >
        <v-toolbar-title>All Popup Response</v-toolbar-title>
        <v-divider
            class="mx-4"
            inset
            vertical
        ></v-divider>
        <v-chip-group>
          <v-chip v-if="popup.option_1" class="pa-8">
            <v-badge
                color="green"
                :content="count['option_1_count']"
            >
              {{ popup.option_1 }} :
            </v-badge>
                        <v-btn icon class="mr-1" @click="exportUser(popup.option_1)">
                          <v-icon color="primary">mdi-cloud-download</v-icon>
                        </v-btn>
          </v-chip>
          <v-chip v-if="popup.option_2" class="pa-8">
            <v-badge
                color="green"
                :content="count['option_2_count']"
            >
              {{ popup.option_2 }} :
            </v-badge>
                        <v-btn icon class="mr-1" @click="exportUser(popup.option_2)">
                          <v-icon color="primary">mdi-cloud-download</v-icon>
                        </v-btn>
          </v-chip>
          <v-chip v-if="popup.option_3" class="pa-8">
            <v-badge
                color="green"
                :content="count['option_3_count']"
            >
              {{ popup.option_3 }} :
            </v-badge>
                        <v-btn icon class="mr-1" @click="exportUser(popup.option_3)">
                          <v-icon color="primary">mdi-cloud-download</v-icon>
                        </v-btn>
          </v-chip>
          <v-chip class="pa-8">
            <v-badge
                color="green"
                :content="count['close_count']"
            >
              Close
            </v-badge>
                        <v-btn icon class="mr-1" @click="exportUser('close')">
                          <v-icon color="primary">mdi-cloud-download</v-icon>
                        </v-btn>
          </v-chip>
        </v-chip-group>
        <v-spacer></v-spacer>
        <v-dialog
            v-model="dialog"
            width="500"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                icon
                v-bind="attrs"
                v-on="on"
            >
              <v-icon>mdi-message-plus</v-icon>
            </v-btn>
          </template>

          <v-card>
            <v-card-title class="headline grey lighten-2">
              Send Message
            </v-card-title>

            <v-card-text>
              <v-form @submit.prevent="sendMessage">
                <v-row>
                  <v-col
                      cols="12"
                  >
                    <v-select
                        :items="[{name: 'SMS',value: 'sms'},{name: 'Notification',value: 'notification'}]"
                        item-text="name"
                        item-value="value"
                        v-model="messageData.channel"
                        label="Select channel"
                    />
                  </v-col>
                  <v-col
                      cols="12"
                  >
                    <v-select
                        :items="popupResponseTypes"
                        v-model="messageData.response"
                        label="Select response"
                    />
                  </v-col>
                  <v-col
                      cols="12"
                  >
                    <v-textarea
                        v-model="messageData.message"
                        label="Write message"
                    />
                  </v-col>
                  <v-col
                      v-if="messageData.channel === 'notification'"
                      cols="12"
                  >
                    <v-text-field
                        v-model="messageData.option_1"
                        label="Option 1"
                    />
                  </v-col>
                  <v-col
                      v-if="messageData.channel === 'notification'"
                      cols="12"
                  >
                    <v-text-field
                        v-model="messageData.option_2"
                        label="Option 2"
                    />
                  </v-col>
                  <v-col
                      v-if="messageData.channel === 'notification'"
                      cols="12"
                  >
                    <v-text-field
                        v-model="messageData.option_3"
                        label="Option 3"
                    />
                  </v-col>
                  <v-col
                      v-if="messageData.channel === 'notification'"
                      cols="12"
                      md="6">
                    <v-select
                        v-model="messageData.type"
                        :items="['course','notice','exam','user_guide','unit','unit_list','sms_alert','eligibility','corrected_questions','wrong_questions','favorite_questions','share','link']"
                        label="Choose type"
                    />
                  </v-col>
                  <v-col
                      v-if="messageData.channel === 'notification'"
                      cols="12"
                      md="6"
                  >
                    <v-text-field
                        v-model="messageData.content_id"
                        label="Content ID"
                    />
                  </v-col>
                  <v-col
                      v-if="messageData.channel === 'notification'"
                      cols="12"
                  >
                    <v-text-field v-model="messageData.link" label="Enter link"></v-text-field>
                  </v-col>
                  <v-col
                      cols="12"
                  >
                    <v-btn type="submit" block color="primary" :disabled="!isValidForm">
                      Send message
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:item.created_at="{ item }">
      {{ item.created_at | calendar }}
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon
          color="blue"
          small
          class="mr-2"
          @click="editItem(item)"
      >
        mdi-pencil
      </v-icon>
      <v-icon
          color="red"
          small
          @click="deleteItem(item)"
      >
        mdi-delete
      </v-icon>
    </template>
    <template v-slot:no-data>
      <v-btn
          color="primary"
          @click="initialize"
      >
        Reset
      </v-btn>
    </template>
  </v-data-table>
</template>

<script>
import axios from "axios";
import moment from "moment";
import Swal from "sweetalert2";

export default {
  name: "PopupResponse",
  components: {},
  data: () => ({
    dialog: false,
    loading: false,
    photo: null,
    footerProps: {
      itemsPerPageOptions: [20, 50, 100, 500]
    },
    pageCount: 0,
    totalPopupResponses: 0,
    options: {},
    headers: [
      {
        text: 'Name',
        value: 'user.name',
      },
      {
        text: 'Phone',
        value: 'user.phone',
      },
      {
        text: 'Group',
        value: 'user.group',
      },
      {
        text: 'Response',
        value: 'opinion',
      },
      {
        text: 'Time',
        value: 'created_at'
      }
    ],
    popupResponses: [],
    popup: {},
    messageData: {
      channel: '',
      response: '',
      message: '',
      link: '',
      type: '',
      content_id: '',
      option_1: '',
      option_2: '',
      option_3: '',
    },
    count: ['']
  }),

  computed: {
    popupResponseTypes() {
      let responses = []
      if (this.popup.option_1) {
        responses.push(this.popup.option_1)
      }
      if (this.popup.option_2) {
        responses.push(this.popup.option_2)
      }
      if (this.popup.option_3) {
        responses.push(this.popup.option_3)
      }
      return responses
    },
    isValidForm() {
      return !!this.messageData.channel && !!this.messageData.response && !!this.messageData.message
    },
    form() {
      let formData = new FormData()
      formData.set('popup_id', this.$route.params.popupId)
      formData.set('channel', this.messageData.channel)
      formData.set('response', this.messageData.response)
      formData.set('message', this.messageData.message)
      if (this.messageData.link) {
        formData.set('link', this.messageData.link)
      }
      if (this.messageData.type) {
        formData.set('type', this.messageData.type)
      }
      if (this.messageData.content_id) {
        formData.set('content_id', this.messageData.content_id)
      }
      if (this.messageData.option_1) {
        formData.set('option_1', this.messageData.option_1)
      }
      if (this.messageData.option_2) {
        formData.set('option_2', this.messageData.option_2)
      }
      if (this.messageData.option_3) {
        formData.set('option_3', this.messageData.option_3)
      }
      return formData
    }
  },

  watch: {
    options: {
      handler() {
        this.initialize()
      },
      deep: true
    }
  },

  created() {
  },

  methods: {
    initialize() {
      this.loading = true
      this.loadCount()
      const url = 'admin/popup-response?id=' + this.$route.params.popupId + '&page=' + this.options.page + '&per_page=' + this.options.itemsPerPage
      axios.get(url).then((response) => {
        this.popupResponses = response.data.data
        this.totalPopupResponses = response.data.total
        this.loading = false
      }).catch(() => {
        this.loading = false
      })
      const popupUrl = 'admin/popup/' + this.$route.params.popupId
      axios.get(popupUrl).then(response => {
        this.popup = response.data
      }).catch(error => {
        console.log(error)
      })
    },
    loadCount() {
      const url = 'admin/popup-response?filter=count&id=' + this.$route.params.popupId
      axios.get(url).then(response => {
        this.count = response.data
        console.log(response.data)
      })
    },
    sendMessage() {
      const url = 'admin/send-message-from-survey'
      this.dialog = false
      axios.post(url, this.form).then(() => {
        Swal.fire('Message sent successfully!')
      }).catch(() => {
        Swal.fire('Failed', 'Message sending failed!', 'warning')
      })
    },
    exportUser(response) {
      const url = 'admin/popup-response?type=export&id=' + this.$route.params.popupId + '&response=' + response
      axios.get(url, {
        headers:
            {
              'Content-Disposition': "attachment; filename=template.xlsx",
              'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            },
        responseType: 'arraybuffer',
      }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        let filename = 'users'
        link.setAttribute('download', filename + '.xlsx');
        document.body.appendChild(link);
        link.click();
      }).catch((error) => {
        console.log(error)
      })
    }
  },

  filters: {
    calendar(value) {
      return moment(value).calendar()
    }
  }
}
</script>

<style scoped>

</style>